<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-card-text v-if="loading">
        <v-row>
          <v-col>
            <v-skeleton-loader type="card"/>
          </v-col>
          <v-col>
            <v-skeleton-loader type="article"/>
          </v-col>
          <v-col>
            <v-skeleton-loader type="article"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row>

          <v-col cols="4" class="d-flex b-r">
            <div class="flex flex-column">

              <v-avatar size="70" rounded class="mb-2">
                <v-img v-if="statistic.task.photo" :src="FILE_URL+statistic.task.photo"></v-img>
                <v-img v-else :src="require('@/assets/no-image.png')"></v-img>
              </v-avatar>

              <h4 class="mb-2">
                 <v-icon x-small left color="blue">mdi-circle</v-icon>
                <span class="text--primary">Campagne : </span>
                {{ statistic.task.campaign.name }}
              </h4>

              <h4 class="mb-2">
                 <v-icon x-small left color="blue">mdi-circle</v-icon>
                <span class="text--primary">Tâche : </span>
                {{ statistic.task.name }}
              </h4>

              <h4 class="mb-2">
                 <v-icon x-small left color="blue">mdi-circle</v-icon>
                <span class="text--primary">  Points gagnants: </span>
                <v-chip color="blue" dark small>{{ statistic.task.winning_points | toCurrency }}</v-chip>
              </h4>

              <h4 class="mb-2">
                 <v-icon x-small left color="blue">mdi-circle</v-icon>
                <span class="text--primary">Total des points : </span>
                <v-chip color="orange" dark small>
                  {{ (statistic.task.winning_points * statistic.validated_total) | toCurrency }}
                </v-chip>
              </h4>

              <h4 class="mb-3">
                 <v-icon x-small left color="blue">mdi-circle</v-icon>
                <span class="text--primary">Total réponses : </span>
                {{ statistic.responses_total }}
              </h4>

              <v-btn to="/backoffice/completed"
                     depressed
                     color="primary"
                     rounded>
                <v-icon left>mdi-arrow-left</v-icon>
                Retour
              </v-btn>

            </div>
          </v-col>

          <v-col cols="4" class="d-flex text-center b-r align-center">
            <div class="flex flex-column">
              <h3 class="mb-3">Raisons </h3>
              <div v-for="(raison,i) in statistic.raisons" :key="i">
                <div class="d-flex justify-space-between mt-4">
                  {{ raison.total }}-{{ raison.name }}
                  <v-spacer/>
                  {{ raison.percent }}%
                </div>
                <v-progress-linear :value="raison.percent"
                                   :color="setColor(raison.percent)"
                                   striped
                                   rounded
                                   height="6"></v-progress-linear>
              </div>
              <h4 class="mt-3">Total : {{ statistic.refused_total }}</h4>
            </div>
          </v-col>

          <v-col cols="4" class="d-flex text-center align-center">
            <div class="flex flex-column">
              <h3 class="mb-3">États </h3>
              <v-row justify="center" align="center">
                <v-col>
                  <v-progress-circular
                      :rotate="360"
                      :size="70"
                      :width="7"
                      :value="statistic.validated_percent"
                      :color="setColor(statistic.validated_percent)">{{ statistic.validated_percent }}%
                  </v-progress-circular>
                  <span class="d-block">Total : {{ statistic.validated_total }}</span>
                  <strong class="d-block">Tâches Validées</strong>
                </v-col>
                <v-col>
                  <v-progress-circular :rotate="360"
                                       :size="70"
                                       :width="7"
                                       :value="statistic.refused_percent"
                                       :color="setColor(statistic.refused_percent)">
                    {{ statistic.refused_percent }}%
                  </v-progress-circular>
                  <span class="d-block">Total : {{ statistic.refused_total }}</span>
                  <strong class="d-block">Tâches Réfusées</strong>
                </v-col>
              </v-row>
            </div>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['statistic', 'loading'],
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
    }
  },
  methods: {
    setColor(el) {
      if (el <= 30) {
        return 'red'
      }
      if (el > 30 && el <= 60) {
        return 'yellow'
      }
      if (el > 60 && el <= 90) {
        return 'primary'
      }
      if (el > 90 && el <= 100) {
        return 'success'
      }
  },
  }
}
</script>

<style scoped>

</style>

